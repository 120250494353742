import { ITableState } from '../../_metronic/shared/crud-table';

export function baseFilter(entities: any[], requestObj: ITableState) {
  // Filtration
  let entitiesResult = filterArray(entities, requestObj);
  // Search
  entitiesResult = searchInArray(entitiesResult, requestObj.searchTerm, requestObj.filterHeaderColumn);

  // Sorting
  // start
  if (requestObj.sorting.column) {
    entitiesResult = sortArray(entitiesResult, requestObj.sorting.column, requestObj.sorting.direction);
  }
  // end

  // Paginator
  // start
  const totalCount = entitiesResult.length;
  const startPosition = (requestObj.paginator.page - 1) * requestObj.paginator.pageSize;
  const endPosition = startPosition + requestObj.paginator.pageSize;
  entitiesResult = entitiesResult.slice(startPosition, endPosition);
  // end

  const responseObj = {
    items: entitiesResult,
    total: totalCount
  };
  return responseObj;
}

export function getNestedFieldValue(item, fieldName): any {
  const levels = fieldName.split('.');
  let value = item;
  try {

    levels.forEach(el => {
      value = value[el];
    });
  }
  catch (e) {
    return '';
  }
  return value ?? '';
}

export function sortArray(incomingArray: any[], sortField: string = '', sortOrder: string = 'asc'): any[] {
  if (!sortField) {
    return incomingArray;
  }

  let result: any[] = [];
  result = incomingArray.sort((a, b) => {
    if (getNestedFieldValue(a, sortField) < getNestedFieldValue(b, sortField)) {
      return sortOrder === 'asc' ? -1 : 1;
    }

    if (getNestedFieldValue(a, sortField) > getNestedFieldValue(b, sortField)) {
      return sortOrder === 'asc' ? 1 : -1;
    }

    return 0;
  });
  return result;
}

export function filterArray(incomingArray: any[], requestObj: ITableState): any[] {
  if (!requestObj || !requestObj.filter) {
    return incomingArray;
  }

  let result: any[] = incomingArray;
  const filtrationFields = Object.keys(requestObj.filter);
  filtrationFields.forEach((keyName: string) => {
    // tslint:disable-next-line:triple-equals
    result = result.filter(el => getNestedFieldValue(el, keyName) == getNestedFieldValue(requestObj.filter, keyName));
  });
  return result;
}

export function searchInArray(incomingArray: any[], searchTerm: string = '', filterColumn: any = {}): any[] {
  return incomingArray.filter(row => {
    let termIsFoundInRow = true;
    for (const keyName of Object.keys(filterColumn)) {
      const searchText = `${filterColumn[keyName] ?? ''}`.toLowerCase();
      if (searchText !== '' && searchText !== null) {
        termIsFoundInRow = termIsFoundInRow && getNestedFieldValue(row, keyName).toString().toLowerCase().indexOf(searchText) > -1;
      }
    }
    return termIsFoundInRow;
  });
}
