import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { User } from '../../../../../models/user.model';
import { UsersTable } from '../../../../../_fake/fake-db/users.table';
import { environment } from '../../../../../../environments/environment';
import { AuthModel } from '../../../_models/auth.model';

const API_USERS_URL = `${environment.apiUrl}/users`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  login(username: string, password: string): Observable<any> {
    const notFoundError = new Error('Not Found');
    if (!username || !password) {
      return of(notFoundError);
    }

    return this.http.post<User>(`${API_USERS_URL}/login`, { username, password }).pipe(
      catchError(err => {
        return of(null);
      }),
      map((result: User) => {
        const auth = new AuthModel();
        result.authToken = result.authToken;
        result.expiresIn = new Date(Date.now() + 70 * 24 * 60 * 60 * 1000);
        return result;
      })
    );
  }

  createUser(user: User): Observable<any> {
    // user.roles = [2]; // Manager
    // user.authToken = 'auth-token-' + Math.random();
    // user.refreshToken = 'auth-token-' + Math.random();
    // user.expiresIn = new Date(Date.now() + 100 * 24 * 60 * 60 * 1000);
    // user.pic = './assets/media/users/default.jpg';

    return this.http.post<User>(API_USERS_URL, user);
  }

  forgotPassword(username: string, password = 'preutesla', notify = true): Observable<boolean> {
    return this.http.put<boolean>(`${API_USERS_URL}/recovery`, { username, password, notify }).pipe(
      catchError(err => {
        return of(false);
      })
    );
  }

  getUserByToken(token: string): Observable<User> {
    const user = UsersTable.users.find((u) => {
      return u.authToken === token;
    });

    if (!user) {
      return of(undefined);
    }

    return of(user);
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(API_USERS_URL);
  }
}
