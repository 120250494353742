import { AuthService } from './../../modules/auth/_services/auth.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('authToken');
    if (token && this.authService.currentUserValue.authToken != token) {
      window.location.reload();
    }

    return next.handle(
      httpRequest.clone({
        setHeaders: {
          'Authorization': token ? `Bearer ${token}` : ''
        }
      })
    ).pipe(
      tap({
        error: (error) => {
          if (error.status === 401) {
            this.authService.logout();
          }
        }
      })
    );
  }
}
