import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  Router,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) { }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.checkUser() && this.checkUserAuthentication(state.url);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkUser() && this.checkUserAuthentication(state.url);
  }

  checkUser() {
    const token = localStorage.getItem('authToken');
    if (token && this.authService.currentUserValue.authToken != token) {
      window.location.reload();
      return false;
    }
    return true;
  }

  checkUserAuthentication(url) {
    const whiteList = ['/dashboard', '/user-profile', '/user-profile/personal-information'];
    const key = localStorage.getItem(this.authService.authLocalStorageToken);
    const expiresIn = localStorage.getItem('expiresIn');
    if (key && new Date(expiresIn)?.getTime() > Date.now()) {

      if (whiteList.findIndex(x => x === url) === -1 && !this.authService.hasPermission(url)) {
        return this.router.parseUrl('/dashboard');
      }
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
  }

}
