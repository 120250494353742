
export function setRoles(roles: number[]): number {
  return roles.reduce((reduce, current) => reduce | Math.pow(2, current), 0);
}

export function allowRole(allows, role): boolean {
  return (allows & Math.pow(2, role)) !== 0;
}

export const ROLES = {
  APODERADO: 1,
  ALUMNO: 2,
  PROFESOR: 3,
  VENDEDOR: 4,
  COORDINADOR: 5,
  ADMINISTRADOR: 6,
  COORDINADORGENERAL: 7,
  RECEPCIONISTA: 8,
}
