import { allowRole, ROLES } from './../../../_metronic/configs/role.config';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { User } from '../../../models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DynamicAsideMenuConfig } from 'src/app/_metronic/configs/dynamic-aside-menu.config';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<User>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<User>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get isAdmin() {
    return this.currentUserValue.roleId === ROLES.ADMINISTRADOR
  }

  get isParent() {
    return this.currentUserValue.roleId === ROLES.APODERADO
  }

  get isProfessor() {
    return this.currentUserValue.roleId === ROLES.PROFESOR
  }
  get isRecepcionista() {
    return this.currentUserValue.roleId === ROLES.RECEPCIONISTA
  }

  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    const user: User = JSON.parse(localStorage.getItem('user'));
    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  // public methods
  login(email: string, password: string): Observable<User> {
    localStorage.clear();
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: User) => {
        const result = this.setAuthFromLocalStorage(auth);
        this.setUser(auth);
        return auth;
      }),
      // switchMap((e) => this.setUser(e)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/auth/login']).then(() => {
      window.location.reload();
    });
  }

  setUser(user, setToken = true) {
    this.currentUserSubject = new BehaviorSubject<User>(user);
    localStorage.setItem('user', JSON.stringify(user));
    if (setToken) {
      localStorage.setItem('expiresIn', user.expiresIn);
      localStorage.setItem('authToken', user.authToken);
      localStorage.setItem('version', environment.appVersion);
    }
  }

  getUserByToken(): Observable<User> {
    const auth = this.getAuthFromLocalStorage();
    return of(undefined);
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: User) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<User>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string, password = 'preutesla', notify = true): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email, password, notify)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  registration(user: User): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      // switchMap(() => this.login(user.userName, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  hasPermission(url: string) {
    let hasPermission = false;
    DynamicAsideMenuConfig.items.forEach((item) => {
      if (item.submenu) {
        item.submenu.forEach((subitem) => {
          if (url.indexOf(subitem.page) === 0
          &&
          allowRole(subitem.permission, this.currentUserValue.roleId)) {

            hasPermission = true;
          }
        });
      }
      else {
        if (url.indexOf(item.page) === 0
        &&
        allowRole(item.permission, this.currentUserValue.roleId)) {
          hasPermission = true;
        }
      }
    });
    return hasPermission;
  }
  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
