import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  post<T>(url, data, contentType = 'application/json') {
    return this.http.post<T>(url, data);
  }

  put<T>(url, data, contentType = 'application/json') {
    return this.http.put<T>(url, data);
  }

  post_external<T>(url, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'Content-Type: application/x-www-form-urlencoded'
      }),
    };

    return this.http.post<T>(url, data, httpOptions);
  }

  get<T>(url, params = null) {
    const httpOptions = {
      params: params?.params
    };
    return this.http.get<T>(url, httpOptions);
  }

  delete(url) {
    return this.http.delete(url);
  }
}
